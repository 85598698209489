import type {WebformSubmissionStatus} from '@/lib/types';
import type {Webform, WebformObject} from 'nextjs-drupal-webform';

import decorImage from '@/assets/webform-img.webp';
import {cn} from '@/lib/utils';

import {withProps} from '@/hoc/withProps';
import {customSubmit} from 'lib/webforms';
import {useTranslation} from 'next-i18next';
import {useReCaptcha} from 'next-recaptcha-v3';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import {useState} from 'react';
import CTAButton from '../atoms/buttons/cta-button';
import Heading from '../atoms/heading';
import Loader from '../atoms/loader';
import WebformSkeleton from './WebformSkeleton';

const DynamicWebform = dynamic(() => import('nextjs-drupal-webform').then(mod => mod.Webform), {
    loading: () => <WebformSkeleton.Webform />,
}) as Webform;
const WebformActions = dynamic(() => import('components/webforms/WebformActions'), {
    loading: () => <WebformSkeleton.Textfield />,
});
const WebformButton = dynamic(() => import('components/webforms/WebformButton'), {
    loading: () => <WebformSkeleton.Button />,
});
const WebformTextarea = dynamic(() => import('components/webforms/WebformTextarea'), {
    loading: () => <WebformSkeleton.Textfield tall />,
});
const WebformTextfield = dynamic(() => import('components/webforms/WebformTextfield'), {
    loading: () => <WebformSkeleton.Textfield />,
});

type Props = {
    webform: WebformObject;
    modal?: boolean;
    onClose?: () => void;
    className?: string;
};

const WebformComponent = ({webform, modal, onClose, className}: Props) => {
    const {executeRecaptcha} = useReCaptcha();
    const {t} = useTranslation();
    const [status, setStatus] = useState<WebformSubmissionStatus | 'idle' | 'submitting'>('idle');
    if (status === 'success')
        return (
            <div className={cn('flex flex-col', className)}>
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: t('sentSuccessfully'),
                    }}
                    className="py-[132px] text-center"
                    tag="h1"
                    type="h1"
                />
                <div className="grid flex-grow grid-cols-3">
                    <span aria-hidden />
                    <CTAButton
                        href={'/'}
                        onClick={Boolean(onClose) && onClose}
                        className="mx-auto w-full max-w-[300px] gap-2 self-start py-[27px] text-sm font-semibold"
                        textColor="text-primary"
                        excludeArrow
                        backgroundColor="bg-black"
                    >
                        {t('returnHome')}
                    </CTAButton>
                    {modal && (
                        <Image
                            src={decorImage.src}
                            alt={''}
                            className="mx-auto h-[200px] w-full max-w-lg object-contain lg:h-auto lg:self-end"
                            width={+decorImage.width}
                            height={+decorImage.height}
                            unoptimized
                        />
                    )}
                </div>
            </div>
        );

    return (
        <div
            className={cn('relative lg:h-[calc(100%_-_83px)] lg:grid-rows-none lg:gap-[40px]', className, {
                'lg:grid lg:grid-cols-[1fr_428px_1fr]': modal,
            })}
        >
            {webform?.title &&
                (!modal ? (
                    <div className={cn('mt-2 pb-6 text-center text-[27px] font-light leading-[35px] lg:text-[40px] lg:leading-[50px]')}>{webform.title}</div>
                ) : (
                    <span></span>
                ))}
            {status === 'submitting' && (
                <span className="absolute left-1/2 top-[calc(50%-115px)] z-10 -translate-x-1/2 -translate-y-1/2 rounded-full bg-gray-900 p-4">
                    <Loader className="w-40" />
                </span>
            )}
            <div
                className={cn('relative', {
                    'self-center': modal,
                })}
            >
                {webform?.title && modal && (
                    <div
                        className={cn(
                            'whitespace-nowrap text-[27px] font-light leading-[35px] lg:text-[40px] lg:leading-[50px]',
                            'mt-2 pb-6 text-center',
                            'lg:absolute lg:right-[calc(428px+2rem)] lg:top-4 lg:mt-0 lg:pb-0 lg:text-right'
                        )}
                    >
                        {webform.title}
                    </div>
                )}
                <DynamicWebform
                    id={webform.id}
                    data={webform}
                    className={cn('mx-auto w-full max-w-xl space-y-3 pb-16 lg:justify-start', {
                        'pointer-events-none opacity-60': status === 'submitting',
                        'self-center lg:pb-[105px]': modal,
                    })}
                    onSubmit={async params => {
                        try {
                            setStatus('submitting');
                            const status = await customSubmit({...params, executeRecaptcha});
                            setStatus(status);
                        } catch (error) {
                            console.error(error);
                            setStatus('error');
                        }
                    }}
                    customComponents={{
                        textfield: WebformTextfield,
                        textarea: WebformTextarea,
                        webform_actions: withProps(WebformActions, {modal}),
                        button: WebformButton,
                        email: WebformTextfield,
                        tel: WebformTextfield,
                    }}
                />
            </div>
            {modal && (
                <Image
                    src={decorImage}
                    alt=""
                    className="mx-auto hidden h-[200px] w-[clamp(100px,45vw,300px)] max-w-lg translate-x-1/2 object-contain md:hidden lg:static lg:block lg:h-auto lg:w-full lg:translate-x-0 lg:self-end"
                />
            )}
        </div>
    );
};

export default WebformComponent;
