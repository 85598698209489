import type {DrupalParagraph} from 'next-drupal';

import arrowImage from '@/assets/info-banner-arrow.svg';
import Body from '@/components/atoms/body';
import {COLOR_VARIANTS} from '@/lib/constants';
import {cn} from '@/lib/utils';
import Image from 'next/image';
import ParagraphHeading from './paragraph--heading';
interface ParagraphInfoBannerProps {
    paragraph: DrupalParagraph;
    className?: string;
}

const ParagraphInfoBanner = ({paragraph, className}: ParagraphInfoBannerProps) => {
    const backgroundColorPrimaryVariants = paragraph.field_primary_background_color
        ? COLOR_VARIANTS[paragraph.field_primary_background_color]
        : 'bg-[radial-gradient(circle_at_70%_0%,_var(--tw-gradient-stops))] from-primary/40 to-primary';

    return (
        <div className={cn('mx-auto lg:w-full', className)}>
            <div className={cn('radia relative rounded-2xl p-[50px] pt-[80px]', backgroundColorPrimaryVariants)}>
                <Image src={arrowImage} alt="" className="absolute left-1/2 top-0 -translate-x-1/2" unoptimized />
                <div className="pb-[40px]">
                    {paragraph.field_heading_paragraph && (
                        <ParagraphHeading className="pt-2 text-center text-[27px] font-semibold leading-[35px]" paragraph={paragraph.field_heading_paragraph} />
                    )}
                </div>

                {paragraph.field_body?.processed && <Body className="mb-11" value={paragraph.field_body?.processed} />}
            </div>
        </div>
    );
};

export default ParagraphInfoBanner;
